import { template as template_4608ca42852042bdb8aae823a47c938d } from "@ember/template-compiler";
const FKLabel = template_4608ca42852042bdb8aae823a47c938d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
