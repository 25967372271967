import { template as template_664be67db0d14b91bd275e54466d44d8 } from "@ember/template-compiler";
const FKControlMenuContainer = template_664be67db0d14b91bd275e54466d44d8(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
