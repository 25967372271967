import { template as template_588da7e2c7794509a59be52d4d18e881 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_588da7e2c7794509a59be52d4d18e881(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
