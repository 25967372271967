import { template as template_15dc3383e79d4eb3bc6324034cc5d0bf } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_15dc3383e79d4eb3bc6324034cc5d0bf(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
