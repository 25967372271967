import { template as template_3a300ca89e6f4f5e88844dcd8f5690b3 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_3a300ca89e6f4f5e88844dcd8f5690b3(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
